import _ from 'lodash';
import React from 'react';

export type DurationUnitT = 'month' | 'day' | 'hour';
export const passwordValidationRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-()|=]).{8,}$/; // must match backend on passwordCheck.ts
export type ResourceOwnerT = string;
export type ResourceProvisionerT = string;
export type ResourceRequestT = {
  requestId: string;
  didApprove: boolean;
  didProvision: boolean;
  isDone: boolean;
};

export type OrgT = {
  id: string;
  name: string;
  hostname: string;
  isSetup: boolean;
  ownerUids: string[];
  pkgId: string;
  facetId: FacetIdEnum;
  customerId?: string;
};

export enum FacetIdEnum {
  FREE_AWS = 'faFreeAws',
  UNLIMITED = 'faUnlimited',
}

export enum PackageNameEnum {
  FREE = 'Free',
  TEAM = 'Team',
  ENTERPRISE = 'Enterprise',
}

export type OrgConfigurationT = {
  oid: string;
  whitelistedDomains: string[] | null;
  resourceImportFrequency: number;
  employeeImportFrequency: number;
  vendorEmployeeReviewFrequency: number;
  serviceAccountReviewFrequency: number;
  maxNotifications?: number;
  periodValueNotifications?: number;
  periodUnitNotifications?: string;
  enabledThrottlingNotifications?: boolean;
  defaultSensitivityId?: string;
};

export enum UserType {
  customer = 'customer',
  system = 'system',
  contractor = 'contractor',
  employee = 'employee',
}

export type currentUserT = {
  id: string;
  email: string;
  firstname?: string;
  lastname?: string;
  managerEmail?: string;
  hasPassword: boolean;
  loginMethod: LoginMethodT;
  isOrgOwner: boolean;
  sourceOfTruth?: string;
  isHRAdmin?: boolean;
};

export enum UserHierarchy {
  REGULAR_USER = 'Regular User',
  MANAGER = 'Manager',
  PROVISIONER = 'Provisioner',
  OWNER = 'Owner',
}

export enum ViewerRole {
  GLOBAL_ADMIN = 'global_admin',
  PRIVILEGED_USER = 'privileged_user',
  REGULAR_USER = 'regular_user',
}

export enum ProfileScreenContext {
  PROFILE_INFO = 'profile_info',
  BASIC_INFO = 'basic_info',
  LOGIN_INFO = 'login_info',
  ROLE_ACCESS_INFO = 'role_access_info',
  USER_PERMISSIONS = 'user_permissions',
  USER_SUMMARY = 'user_summary',
  USER_HISTORY = 'user_history',
  TEAMS_INFO = 'teams_info',
  TEAMS_MEMBERS = 'teams_members',
  ACCESS_HISTORY = 'access_history',
  ACCOUNT_NOTES = 'account_notes',
}

/** @deprecated use domainObjects/User */
export type UserT = {
  id: string;
  oid?: string;
  created: string;
  firstname: string;
  loginMethod?: string;
  lastname: string;
  email: string;
  isOrgOwner: boolean;
  tombstone: boolean | null;
  managerEmail?: string;
  managerUid?: string;
  manager?: UserT;
  type: string;
  hasPassword: boolean;
  confirmed: boolean;
  lastLogin: string;
  terminated: boolean;
  allowLogin: boolean;
  expiresTs?: string;
  startDate?: string;
  createdByEmail?: string;
  title?: string | null;
  department?: string;
  remoteRole?: string;
  systems?: number;
  permissions?: number;
  onboardingMode?: OnboardingModeE;
};

export type TeamT = {
  id: string;
  name: string;
  description: string;
  tid: string;
  oid: string;
  managed?: boolean;
  color?: string;
  type: string;
  memberCount?: number;
  members?: UserT[];
  managers?: UserT[];
};

export type MinUserT = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  terminated?: boolean;
  isOrgOwner?: boolean;
  oid?: string;
  created?: string;
  type?: string;
  tombstone?: boolean | null;
};

export type JobT = {
  jobType: string;
};

/**
 * @deprecated use stores/domainObjects/Permission
 * */
export type PermissionT = {
  id: string;
  oid: string;
  refId: string;
  sid?: string;
  refType: string;
  label: string;
  description: string | null;
  hidden?: boolean;
  createdAt?: string;
  approvalDefinition?: string;
  provisionInstructions?: string;
  reapprovalDefinition?: string;
  provisionDefinition?: string;
  deprovisionDefinition?: string;
  accessInstructions?: string;
  durationValue?: number;
  durationUnit?: DurationUnitT;
  accessDurationValue?: number;
  accessDurationUnit?: DurationUnitT;
  autoProvision?: boolean;
  accesses?: AccessRecordT[];
  numAccounts?: number; // present only on resources returned by /resources/:rid
  sensitivityId?: string;
  tombstone?: boolean | null;
  connectorData?: any[];
  updated?: string;
  reviewedAt?: string;
  lastChange?: ResourceHistoryItemT;
  isLocked?: boolean;
};

export type DurationT = {
  durationUnit?: DurationUnitT;
  durationValue?: number;
};

export enum ConnectionServiceE {
  GITHUB = 'github',
  SLACK = 'slack',
  AWS = 'aws',
  GAPPS = 'gapps',
  AZURE_AD = 'azure_ad',
  OKTA = 'okta',
  GENERIC = 'generic',
  PAGERDUTY = 'pagerduty',
  STRONGDM = 'strongdm',
  CLOUDFLARE = 'cloudflare',
  TABLEAU = 'tableau',
  GCP = 'gcp',
  JIRA = 'jira',
  CUSTOM = 'custom',
  CUSTOM_SYSTEM = 'custom-system',
  M365 = 'm365',
  AWS_IDENTITY_CENTER = 'aws_identity_center',
}

export enum AuthorityServiceE {
  AZURE_AD = ConnectionServiceE.AZURE_AD,
  OKTA = ConnectionServiceE.OKTA,
  GAPPS = ConnectionServiceE.GAPPS,
}

export enum ImportStateServiceE {
  // usage
  AWS_USAGE_SERVICE = 'aws_usage_service',
  OKTA_USAGE_SERVICE = 'okta_usage_service',

  // reporting
  AWS_CREDENTIAL_REPORT_SERVICE = 'aws_credential_report',
}

export type UsageServiceMetadata = {service: ImportStateServiceE; info?: React.ReactNode; moreInfoLink?: string};

export const UsageServiceByConnectionType: Record<ConnectionServiceE, UsageServiceMetadata | null> = {
  [ConnectionServiceE.AWS]: {
    service: ImportStateServiceE.AWS_USAGE_SERVICE,
    info: (
      <div>
        If <strong>enabled</strong>, Truste automatically imports usage data for all users. Note that certain policies
        are required to retrieve this data. Retrieving usage data may produce a load on the AWS API, so this is disabled
        by default to ensure system performance. You can always re-enable this feature if you'd like to view this
        additional usage data.
      </div>
    ),
    moreInfoLink: 'https://docs.aws.amazon.com/IAM/latest/APIReference/API_GenerateServiceLastAccessedDetails.html',
  },
  [ConnectionServiceE.AZURE_AD]: null,
  [ConnectionServiceE.GAPPS]: null,
  [ConnectionServiceE.GITHUB]: null,
  [ConnectionServiceE.GENERIC]: null,
  [ConnectionServiceE.OKTA]: {
    service: ImportStateServiceE.OKTA_USAGE_SERVICE,
    info: 'Enable this feature to allow Trustle to automatically import usage data for all applications.',
  },
  [ConnectionServiceE.PAGERDUTY]: null,
  [ConnectionServiceE.SLACK]: null,
  [ConnectionServiceE.STRONGDM]: null,
  [ConnectionServiceE.TABLEAU]: null,
  [ConnectionServiceE.GCP]: null,
  [ConnectionServiceE.CLOUDFLARE]: null,
  [ConnectionServiceE.JIRA]: null,
  [ConnectionServiceE.CUSTOM]: null,
  [ConnectionServiceE.CUSTOM_SYSTEM]: null,
  [ConnectionServiceE.M365]: null,
  [ConnectionServiceE.AWS_IDENTITY_CENTER]: null,
};

export const CredentialReportServiceByConnectionType: Record<ConnectionServiceE, ImportStateServiceE | null> = {
  [ConnectionServiceE.AWS]: ImportStateServiceE.AWS_CREDENTIAL_REPORT_SERVICE,
  [ConnectionServiceE.AZURE_AD]: null,
  [ConnectionServiceE.GAPPS]: null,
  [ConnectionServiceE.GITHUB]: null,
  [ConnectionServiceE.GENERIC]: null,
  [ConnectionServiceE.OKTA]: null,
  [ConnectionServiceE.PAGERDUTY]: null,
  [ConnectionServiceE.SLACK]: null,
  [ConnectionServiceE.STRONGDM]: null,
  [ConnectionServiceE.TABLEAU]: null,
  [ConnectionServiceE.GCP]: null,
  [ConnectionServiceE.CLOUDFLARE]: null,
  [ConnectionServiceE.JIRA]: null,
  [ConnectionServiceE.CUSTOM]: null,
  [ConnectionServiceE.CUSTOM_SYSTEM]: null,
  [ConnectionServiceE.M365]: null,
  [ConnectionServiceE.AWS_IDENTITY_CENTER]: null,
};

export type LatestImportStateT = {importStates: ImportStateT[]; jobs: _.Dictionary<GraphileJobT>};

export enum ResourceTabMode {
  overview = 'overview',
  recommendations = 'recommendations',
  analysis = 'analysis',
  edit = 'edit',
  permissions = 'permissions',
  resources = 'resources',
  graph = 'graph',
  risk = 'risk',
  accounts = 'accounts',
  history = 'history',
  settings = 'settings',
  loading = 'loading',
  oncall = 'oncall',
}

export const getConnectionServiceName = (service: string | undefined): string => {
  switch (service) {
    case ConnectionServiceE.AWS:
      return 'AWS';
    case ConnectionServiceE.AZURE_AD:
      return 'Azure AD';
    case ConnectionServiceE.GITHUB:
      return 'GitHub';
    case ConnectionServiceE.GAPPS:
      return 'Google Workspace';
    case ConnectionServiceE.PAGERDUTY:
      return 'PagerDuty';
    case ConnectionServiceE.M365:
      return 'M365';

    case 'custom-system':
      return 'Custom';

    default:
      return _.startCase(service);
  }
};

export enum ImportStatus {
  FINISHED = 'finished',
  PENDING = 'pending',
  FAILED = 'failed',
  ABORTED = 'aborted',
}

export enum TeamTypeEnum {
  OWNER = 'owner',
  ADHOC = 'adhoc',
  ORGANIZATION = 'organization',
}

export type GraphileJobT = {
  id: number;
  queueName: string;
  taskIdentifier: string;
  attempts: number;
  maxAttempts: number;
  lastError: any;
  createdAt: any;
  updatedAt: any;
  payload: any;
  runAt: string;
  lockedBy: string | null;
  lockedAt: string | null;
};
export type ImportStateTState = {
  message?: string;
  codeError?: string;
  hint?: string;
  step?: string;
  rawMessage?: any;
} | null;
export type ImportStateT = {
  id?: string;
  created: string;
  status: ImportStatus | string;
  state?: ImportStateTState;
  service: string;
  progressStatus: ProgressStatusInfo;
  jobs?: GraphileJobT[];
};

export type AuthzOwnerAWS = {
  Path?: string;
  UserName?: string;
  UserId?: string;
  Arn?: string;
  AccountId?: string;
};

export type ResourceConnectorT = {
  lastImport?: ImportStateT;
  lastImportServiceUsage?: ImportStateT;
  service: ConnectionServiceE;
  serviceFriendlyName: string;
  retrieveUsageData?: boolean;
  pollDurationUnit: DurationUnitT;
  pollDurationValue: number;
  disabled?: boolean;
  provisionMethod: ProvisionMethodE;
  authzOwner?: AuthzOwnerAWS;
  accessKeyRotationValue?: number;
  accessKeyRotationUnit?: DurationUnitT;
  autoProvisionEnabled?: boolean;
  autoDeprovisionEnabled?: boolean;
  initiateExpiredDeprovision?: boolean;
  provisionMode?: ProvisionOptions;
  deprovisionMode?: ProvisionOptions;
};

export type UpdateConnectorSettingsT = {
  accessKeyRotation?: DurationT;
  autoDeprovisionEnabled?: boolean;
  autoProvisionEnabled?: boolean;
  disabled?: boolean;
  initiateExpiredDeprovision?: boolean;
  pollFrequency?: DurationT;
  provisionMethod?: ProvisionMethodE;
  retrieveUsageData?: boolean;
  idpAssociated?: boolean;
  idpEnabled?: boolean;
  idpService?: string;
  idpFieldMapping?: FieldMappingT;
  idpOtherSettings?: IdpIntegrationOtherSettingsT;
  visibility?: 'visible' | 'hidden';
  sensitivityId?: string;
  approvalDuration?: {
    durationValue: number;
    durationUnit: DurationUnitT;
  };
  accessDuration?: {
    durationValue: number;
    durationUnit: DurationUnitT;
  };
  provisionMode?: ProvisionOptions | number | null;
  deprovisionMode?: ProvisionOptions | number | null;
};

export interface ResourceHistoryItemT {
  actor: string;
  changeBy?: UserT;
  created?: string;
  affectedUser?: UserT;
  affectedResource?: ResourceT;
  affectedPermission?: PermissionT;
  action?: ActionType;
}

export type ActionType =
  | ResourceActivity
  | 'approval'
  | 'revoke'
  | 'provision'
  | 'deprovision'
  | 'accessRequest'
  | 'resourceDeleted';

export enum TaskActions {
  APPROVE = 'approve',
  DENY = 'deny',
  CANCEL = 'cancel',
  PROVISION = 'provision',
  DEPROVISION = 'deprovision',
  REAPPROVAL = 'reapprove',
  EXTEND_ACCESS = 'extend_access',
}

export enum AccessRequestAction {
  APPROVE = 'approve',
  REJECT = 'reject',
  COMPLETE = 'complete',
  CANCEL = 'cancel',
  REAPPROVE = 'reapprove',
  EXTEND = 'extend',
  REASSIGN = 'reassign',
  DENY = 'deny',
  GRANT = 'grant',
  VERIFY_USER = 'verify_user',
  OFFBOARD_USER = 'offboard_user',
}

export enum TaskActionsEnum {
  APPROVE = 'approve',
  RENEW = 'renew_access',
  REJECT = 'reject',
  COMPLETE = 'completed',
  CANCEL = 'cancel',
  REAPPROVE = 'reapprove',
  REASSIGN = 'reassign',
  DENY = 'deny',
  GRANT = 'grant',
  PROVISION = 'provision',
  DEPROVISION = 'deprovision',
  EXTEND_ACCESS = 'extend_access',
  RENEW_ACCESS = 'renew_access',
  VERIFY_USER = 'verify_user',
  OFFBOARD_USER = 'offboard_user',
}

/**
 * @deprecated use stores/domainObjects/Resource
 */
export type ResourceT = {
  id: string;
  oid?: string;
  name: string;
  description: string;
  icon?: string;
  userHasAccess?: boolean;
  owners: ResourceOwnerT[];
  provisioners: ResourceProvisionerT[];
  deprovisionDefinition?: string;
  reapprovalDefinition?: string;
  durationValue?: number;
  durationUnit?: DurationUnitT;
  accessDurationValue?: number;
  accessDurationUnit?: DurationUnitT;
  accessInstructions?: string;
  provisionInstructions?: string;
  approvalDefinition?: string;
  provisionDefinition?: string;
  parentId?: string;
  rootSid?: string;
  autoProvision?: boolean;
  requests: ResourceRequestT[];
  permissions?: PermissionT[];
  hidden?: boolean;
  archived?: boolean;
  deletedAt?: string;
  pendingSetup?: boolean;
  connectionId?: string | null;
  connector?: ResourceConnectorT | null;
  connection?: Partial<ResourceConnectorT>;
  createdAt?: string;
  reviewedAt?: string;
  sensitivityId?: string;
  lastChange?: ResourceHistoryItemT;
  isLocked?: boolean;
  ancestors?: ResourceT[];
  provisionMode?: ProvisionOptions;
  deprovisionMode?: ProvisionOptions;
};

// TODO(RS): Rename to something that better describes top-level resource information.
export type TopResourceT = Omit<ResourceT, 'permissions'>;

export type AccessRecordStateT = {
  iA?: boolean /* isApproved */;
  iP?: boolean /* isProvisioned */;
  iPA?: boolean /* isPendingApproval */;
  iPP?: boolean /* isPendingProvisioning */;
  iPD?: boolean /* isPendingDeprovision */;
  wAE?: string /* whenApprovalExpires */;
  iPR?: boolean /* isPendingReapproval */;
};

export enum LoginMethodsE {
  GOOGLE = 'google',
  PASSWORD = 'password',
  SAML = 'saml',
}

export type LoginMethodT = LoginMethodsE.GOOGLE | LoginMethodsE.PASSWORD | LoginMethodsE.SAML;
export type ForeignDataT = {
  firstName?: string;
  lastName?: string;
  email?: string;
  arn?: string;
};

export enum EventTypeT {
  login_success = 'login_success',
  last_activity = 'last_activity',
}

export enum GetByAncestorsE {
  HIDDEN = 'hidden',
  AUTOPROVISION = 'autoProvision',
}

export enum GetDurationByAncestorsE {
  APPROVAL_DURATION = 'duration',
  ACCESS_DURATION = 'accessDuration',
}

export enum AccountStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  SUSPENDED = 'suspended',
  OFFBOARDED = 'offboarded',
}

export enum OnboardingModeE {
  MANUAL = 'manual',
  CONNECTOR = 'connector', // i.e. account imported from connector, user created from connector during assignment
  CSV = 'csv',
  IDP = 'idp',
}

export enum SourceOfTruthE {
  TRUSTLE = 'trustle',
  IDP = 'idp',
}

/** @deprecated use the Account domainObject */
export type AccountT = {
  id: string;
  oid: string;
  rid: string;
  forResource: ResourceT;
  uid: string;
  refId: string;
  forUser: UserT;
  account: string;
  createdByUser?: UserT;
  editedByUser?: UserT;
  createdByUid?: string;
  editedByUid?: string;
  editedByRole?: string;
  accountType?: string;
  createdAt?: string;
  editedAt?: string;
  placeholder?: boolean;
  foreignData?: ForeignDataT;
  foreignId?: string;
  notes?: NoteT[];
  status?: AccountStatus;
  reviewedAt?: string;
  lastLogin?: string;
  deletedRemotely?: boolean;
  onboardingMode?: OnboardingModeE;
};

/**
 * @deprecated use stores/domainObjects/AccessRecord
 */
export type AccessRecordT = {
  id: string;
  state: AccessRecordStateT;
  username?: string;
  oid: string;
  pid: string;
  uid: string;
  uaid?: string;
  accountType?: string;
  forUser?: UserT;
  changeBy?: string;
  changeReason?: string;
  changeId?: string;
  forResource?: ResourceT;
  forAccount?: AccountT;
  task?: TaskT;
  forPermission: PermissionT;
  isApproved: boolean;
  isProvisioned: boolean;
  expiresTs?: string;
  accessExpiresTs?: string;
  jobs: JobT[];
  created?: string;
  reviewedAt?: string;
  changedAt?: string;
  accessDurationUnit?: string;
  accessDurationValue?: string;
  durationUnit?: string;
  durationValue?: string;
};

export type NodeChangeT = {
  id: string;
  actor: string;
  actorData: any;
  action: string;
  oid: string;
  uid?: string;
  email: string;
  firstname?: string;
  lastname?: string;
  value?: any;
  oldValue?: any;
  refType?: string;
  refId?: string;
  created?: string;
};

export type NoteT = {
  id: string;
  comment: string;
  uaid: string;
  isOrgOwner?: boolean;
  createdByUid: string;
  created?: string;
  createdBy?: {id: string; firstname?: string; lastname?: string; email?: string};
};

export type AccessChangeRecordT = {
  id: string;
  createdAt: string;
  accessRequestId?: string;
  forAccessId: string;
  changeType: string;
  changeStatus: string;
  startedByUid: string;
  startedByUser: UserT;
  changeReason?: string;
  changeWorkflowId?: string;
  changeResult?: any;
  changeResultTimestamp?: string;
  changeResultReason?: string;
  durationValue?: number;
  durationUnit?: DurationUnitT;
};

export type AccessRecordHistoryT = {
  id: string;
  created: string;
  accountType: string;
  rid: string; //Resource id
  oid: string;
  uid: string; // user, or owner.
  parentId: string;
  parentType: string;
  pid: string; // permission id
  username: string | null;
  expiresTs?: string;
  isApproved: boolean;
  isProvisioned: boolean;
  changeId?: string;
  changeBy?: string;
  changeReason?: string;
};

export type TaskT = {
  taskId: string;
  accessid: string;
  jobid: string;
  assignedToUids: string;
  assignedToUsers: UserT[];
  created: string;
  type: string;
  status: string;
  isDone: boolean;
  statusChange: any;
  rid: string;
  rootSid: string;
  permissionLabel: string;
  permissionDescription: string;
  provisionInstructions: string;
  requestsReason: string;
  requestsForUid: string;
  usersEmail: string;
  usersFirstname: string;
  usersLastname: string;
  requestsRids: string;
  requestsExpires: string | null;
  requestsProvisionTs: any;
  requestsDidProvision: any;
  systemName: string;
  systemId: string;
  systemIcon: string;
  systemDescription: string;
  resourceName: string;
  resourceDescription: string;
  durationValue: number;
  durationUnit: DurationUnitT;
};

export type TicketT = {
  ticketId: number;
  createdAt: string;
  ticketType: TicketType;
  params: Record<string, any>;
  description?: string;
  ticketStatus: string;
  forOid?: string;
  forUid?: string;
  forPid?: string;
  forAccessId?: string;
  startedByUid?: string;
  wfId?: string;
  wfTaskIdx?: number;
  assignedToUids?: string[];
  notificationsSentAt?: string;
  assignedToUsers: UserT[];
  closedByUid?: string;
  closedAt?: string;
};

// Placeholder until /api/access/requests returns an actual item here.
export type AccessRequestT = {
  jobName: string;
  jobContextId: string;
  jobid: string;
  jobContextCreatedAt: string;
  permissionLabel: string;
  taskId: string;
  resourceName: string;
  jobContextStatus: string;
  jobContextJobType: string;
  taskType: string;
  taskIsDone: boolean;
  taskUserEmail: string;
};

export type ChangeRecordT = {
  id: string;
  createdAt: string;
  forAccessId: string;
  changeType: string;
  changeStatus: string;
  startedByUid: string;
  changeReason?: string;
  changeWorkflowId?: string;
  changeResult?: any; // wf_result sql type?
  changeResultTimestamp?: string;
  changeResultReason?: string;
  durationValue?: number;
  durationUnit?: DurationUnitT;
  targetUid: string;
  resourceUsername?: string;
};

export enum ResourceActivity {
  MODIFIED = 'resourceModified',
  ADDED_OWNER = 'resourceOwnerAdded',
  REMOVED_OWNER = 'resourceOwnerRemoved',
  ADDED_PROVISIONER = 'resourceProvisionerAdded',
  REMOVED_PROVISIONER = 'resourceProvisionerRemoved',
  ARCHIVED = 'resourceArchived',
}

export enum TicketType {
  WF_MANAGER_APPROVAL = 'accessWfManagerApproval',
  WF_OWNER_APPROVAL = 'accessWfOwnerApproval',
  WF_PROVISION = 'accessWfProvision',
  WF_DEPROVISION = 'accessWfDeprovision',
  WF_MGR_RENEWAL = 'accessWfManagerRenewal',
  WF_DESIGNATE_APPROVER = 'accessWfDesignateApprover',
  WF_EXECUTIVE_APPROVAL = 'accessWfExecutiveApproval',
  WF_LINK_USER = 'linkUser',

  // Requestor wants access to a resource, and the provisioner/owner chooses the permissions.
  DIRECTED_ACCESS_REQUEST = 'directedAccessRequest',
}

export type ResourceHistoryT = {
  id: string;
  oid: string;
  traceId: string;
  created: string;
  actor: string;
  rid: string;
  action: ResourceActivity;
  value: Record<string, any>;
  oldValue?: Record<string, any>;
};

export type GenericAcessRequestChangeT = {
  id: string;
  uid: string;
  created: string;
  type: string;
  params: Record<string, any>;
};
export type GenericAccessRequestT = {
  id: string;
  oid: string;
  uid: string;
  message: string;
  createdAt: string;
  assigneeIds: string[];
};

export type AccessRequestCommentT = {
  id: string;
  oid: string;
  uid: string;
  accessRequestId: string;
  message: string;
  createdAt: string;
  forUser: MinUserT;
};

export type AccessWithAdditionalInfo = AccessRecordT & {
  permissionLabel?: string;
  accessRequestId?: string;
  durationValue?: number;
  durationUnit?: string;
  accessDurationValue?: number;
  accessDurationUnit?: string;
};

export type DenormGenericAccessRequestChangeT = GenericAcessRequestChangeT & {
  forUser: MinUserT;
  assignees?: MinUserT[];
  originalAssignees?: MinUserT[];
  forAccess?: AccessWithAdditionalInfo;
};

export enum AccessRequestType {
  GENERIC = 'generic',
  TICKET = 'ticket',
  PROVISIONAL_USER = 'provisional_user',
}

export type TaskTypesEnum = TicketType | AccessRequestType;

export const TaskTypesLabelEnum: Record<TaskTypesEnum, string> = {
  [AccessRequestType.GENERIC]: 'Access Request',
  [AccessRequestType.TICKET]: 'Generic Task',
  [AccessRequestType.PROVISIONAL_USER]: 'Verify User',
  [TicketType.WF_DEPROVISION]: 'Deprovision',
  [TicketType.WF_MGR_RENEWAL]: 'Access Renewal',
  [TicketType.WF_PROVISION]: 'Provision',
  [TicketType.WF_LINK_USER]: 'Link User',
  [TicketType.WF_EXECUTIVE_APPROVAL]: 'Executive Approval',
  [TicketType.WF_MANAGER_APPROVAL]: 'Manager Approval',
  [TicketType.WF_OWNER_APPROVAL]: 'Owner Approval',
  [TicketType.DIRECTED_ACCESS_REQUEST]: 'Direct Request',
  [TicketType.WF_DESIGNATE_APPROVER]: 'Designate Approver',
};

export enum OwnerAccountAction {
  START_REVOKE = 'revoke',
  START_PROVISION = 'provision',
  START_DEPROVISION = 'deprovision',
  ACCEPT = 'accept',
  CANCEL_PENDING = 'cancel',
}

export type ConnectionT = {
  id: string;
  oid: string;
  created: string;
  updated: string;
  service: ConnectionServiceE;
  serviceFriendlyName?: string;
  provisionMethod: ProvisionMethodE;
  authenticationMethod: string;
  pollDurationUnit: string;
  pollDurationValue: string;
  authzOwner?: any;
};

export type AuthenticationProofT = {
  id: string;
  created: string;
  service: string;
  serviceId: string;
  serviceFriendlyName?: string;
};

export enum PolicyTypeAWS {
  GroupPolicyList,
  AttachedManagedPolicies,
}

export type AWSData = {
  PolicyType: PolicyTypeAWS;
  PolicyName: string;
  PolicyArn: string;
  PolicyDocument: string;
};

export type M365Data = {
  lastActivity: string;
};

export enum AzureMetadataTypeE {
  PERMISSIONS = 'permissions',
  GROUP_MEMBERSHIPS = 'group memberships',
}

export type PermissionMetadataT = {
  id: string;
  name: string;
  metadataType: string;
  details: {
    description: string;
    scopes?: [{type: string; resource: string}];
    jsonData: any[];
  };
};
export type ThirdPartyDataOT = {
  data: Record<string, any>;
};

export type AWSPolicyDataT = {
  identifier: string;
  data: {
    PolicyType: PolicyTypeAWS;
    PolicyName: string;
    PolicyArn: string;
    PolicyDocument: string;
  };
};

export type DataParamsT = Record<string, unknown> | string | AWSPolicyDataT | ThirdPartyDataOT | AWSData | M365Data;
export type ConnectorsDataT = {
  id: string;
  oid: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  refType: string;
  refId: string;
} & (
  | {dataType: ConnectorsDataDataTypeE.THIRD_PARTY_CONNECTED_APPS; data: ThirdPartyDataOT}
  // TODO jg: make these types narrower
  | {dataType: ConnectorsDataDataTypeE.USER_ADDITIONAL_DATA; data: DataParamsT}
  | {dataType: ConnectorsDataDataTypeE.CREDENTIAL_REPORT; data: DataParamsT}
  | {dataType: ConnectorsDataDataTypeE.ENTITY_DATA; data: DataParamsT}
);

export type ConnectionInfoT = {
  authenticationProofs: AuthenticationProofT[];
  connection: ConnectionT;
};

export enum ProvisionMethodE {
  manual = 'manual',
  automatic = 'automatic',
}

export enum ProvisionOptions {
  off = 'off',
  manual = 'manual',
  interactive = 'interactive',
  automatic = 'automatic',
}

export const ProvisionMode = {
  [ProvisionOptions.off]: 0,
  [ProvisionOptions.manual]: 1,
  [ProvisionOptions.interactive]: 2,
  [ProvisionOptions.automatic]: 3,
};

export enum AccessStatusE {
  UnlinkedAccount,
  Revoked,
  Deprovisioning,
  Pending,
  Approved,
  Active,
}

export enum GithubAuthenticationMethodE {
  APP_WRITE = 'app_write',
  APP_READ = 'app_read',
}

export enum GAppsAuthenticationMethodE {
  APP_WRITE = 'app_write',
  APP_READ = 'app_read',
}

export enum MetadataType {
  PERMISSION_DEFINITION,
  RELATED_PERMISSION,
}

export type PermissionMetadata = {
  id: string;
  name: string;
  metadataType: MetadataType;
  details: Record<string, any>;
};

export type MinSensitivitySettingT = {
  id: string;
  oid: string;
  level: string;
  maxApprovalDurationValue: number;
  maxApprovalDurationUnit: DurationUnitT;
  maxAccessDurationValue: number;
  maxAccessDurationUnit: DurationUnitT;
};
export type statusWKey = {
  key: number;
  label: string;
  count: number;
};

export type AccessResponseT = AccessItemResponseT[];

export type AccessItemResponseT = {
  id: string; // Unique to a chain of permission changes.
  created: string;
  oid: string;
  uid?: string; // user, or owner.
  rid?: string;
  uaid?: string; //account mapping
  accountType: AccountAccessType; // human controlled
  pid: string; // permission id
  expiresTs?: string;
  accessExpiresTs?: string;
  isApproved: boolean;
  isProvisioned: boolean;
  changeId?: string;
  changeBy?: string;
  changeResultTimestamp?: string;
  changeCreatedAt?: string;
  changeReason?: string;
  reviewedAt?: string;
  state: AccessRecordStateT;
};

export type SensitivitySettingT = MinSensitivitySettingT & {
  approvalDefinition: string;
  provisionDefinition: string;
  deprovisionDefinition: string;
};

export enum SensitivityLevelsE {
  NONE = 'none',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical',
}

export type SetupStepT = {
  id: string;
  description: string;
  name: string;
  componentName: string;
  finalized?: boolean;
};

export enum JWTTokenStateE {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  REVOKED = 'revoked',
}

export type JWTToken = {
  id: string;
  issued: string;
  expires: string;
  notbefore: string;
  lastUsed?: string;
  oid: string;
  uid: string;
  iss: string;
  aud: string;
  state: JWTTokenStateE;
  sub: string;
  description: string;
};

export type PackageT = {
  id: string;
  name: string;
};

export type UserSetupStepT = {
  id: string;
  uid: string;
  oid: string;
  stepId: string;
  componentName: string;
};
export type TestConnectionResponseT = {ok: boolean; message?: string; authorizedBy?: string; error: {message: string}};

export enum AccountAccessType {
  PERSONAL = 'personal',
  SERVICE = 'service',
}

export type UserOddity = {
  uid: string;
  username: string;
  oddity_data: Array<{pid: string; score: number}>;
};

export type AllTeamsOddity = {
  tid: string;
  data: Array<{
    uid: string;
    user_name: string;
    global_user_oddity: number;
  }>;
};

export type UserOddityT = {
  permissions: string[];
  user_name: string;
  cluster_label: number;
  u_oddity_by_pid_set: number;
  pid_team_oddity: number[];
  pid_cluster_oddity: number[];
  pid_usage_oddity: number[];
  global_user_oddity: number;
  access_oddity: number[];
};

export type AccessRecordElemT = {
  id: string;
  isLoading: boolean;
  accessRecord: AccessRecordT;
};

export type ResourceFiltersT = {
  user: string | null;
  resource: string | null;
  account: string | null;
  permission: string | null;
  sensitivity: string | null;
  state: string | null;
};

export type QSFilter = 'account' | 'permission' | 'resource' | 'state';

export enum PermissionModalAction {
  REQUEST_PROVISION = 'requestProvision',
  MARK_AS_REVIEWED = 'markAsReviewed',
  REQUEST_ACCESS = 'requestAccess',
  EDIT_PERMISSION = 'editPermission',
  DELETE_PERMISSION = 'deletePermission',
  VIEW_CONNECTOR_DATA = 'viewConnectorData',
  GRANT_PERMISSION = 'grantPermission',
  CHANGE_SENSITIVITY = 'changeSensitivity',
  CHANGE_VISIBILITY = 'changeVisibility',
  CHANGE_AUTOPROVISION = 'changeAutoProvision',
  VIEW_DETAILS = 'viewDetails',
}

export enum AccountModalAction {
  EDIT = 'edit',
  DETAILS = 'details',
  UNASSIGN = 'unlink',
}

export enum SystemSetupStepId {
  ACCOUNTS = 'accounts',
  RESOURCE = 'resources',
  ACCESS = 'accesses',
}

export type SystemSetupStepT = {
  number: number;
  id: SystemSetupStepId;
  title: string;
  subtitle: string;
  description: string;
  done: boolean;
  selected: boolean;
  enabled: boolean;
};

export enum ConnectorsDataDataTypeE {
  THIRD_PARTY_CONNECTED_APPS = '3rd_party_connected_apps',
  USER_ADDITIONAL_DATA = 'user_additional_data',
  CREDENTIAL_REPORT = 'credential_report',
  ENTITY_DATA = 'entity_data',
}

export type connectorsUsageServiceAccountDataT = {
  account: string;
  eventDate: string | null;
  uaId: string | null;
  uId: string | null;
  eventType: string;
};
export type connectorsUsageServiceT = {
  description: string;
  identifier: string;
  id: string;
  accesses: boolean;
  account: connectorsUsageServiceAccountDataT[];
};

export enum AccessTypeE {
  ACTIVE = 'active',
  AVAILABLE = 'available',
}

export enum OktaNotManageableApps {
  OKTA_ADMIN_CONSOLE = 'Okta Admin Console',
  OKTA_DASHBOARD = 'Okta Dashboard',
  OKTA_BROWSER_PLUGIN = 'Okta Browser Plugin',
}

export type PageableServiceT = {
  service: string;
  systemId: string;
  systemName: string;
  permissionId: string;
  permissionName: string;
};

export enum AccessRequestChangeType {
  MANAGER_APPROVED = 'managerApproved',
  MANAGER_REJECTED = 'managerRejected',
  OWNER_APPROVED = 'ownerApproved',
  OWNER_REJECTED = 'ownerRejected',
  DESIGNATED_APPROVED = 'designatedApproved',
  DESIGNATED_REJECTED = 'designatedRejected',
  EXECUTIVE_APPROVED = 'executiveApproved',
  EXECUTIVE_REJECTED = 'executiveRejected',
  PROVISIONED = 'provisioned',
  PROVISION_CANCELED = 'provisionCanceled',
  GRANT_PERMISSION = 'grantPermission',
  ASSIGNED_ACCOUNT_NAME = 'linkedAccountName',
  MODIFIED_APPROVAL_DURATION = 'modifiedApprovalDuration',
  MODIFIED_ACCESS_DURATION = 'modifiedAccessDuration',
  DEPROVISIONED = 'deprovisioned',
  DEPROVISION_CANCELED = 'deprovisionCanceled',
  REASSIGNED = 'reassigned',
  COMPLETED = 'completed',
  COMMENTED = 'commented',
  CLOSED = 'closed',
}

export type ServiceUsageT = {
  description: string;
  identifier: string;
  id: string;
  accessed: boolean;
  account: {account: string; eventDate: string; uaId: string; uId: string; eventType: string}[];
  csDescription?: string;
  eventDate?: string;
  eventType: EventTypeE;
};

export enum EventTypeE {
  LAST_ACCESSED = 'last_accessed',
  LAST_UPDATED = 'last_updated',
  LAST_MFA_RESET = 'last_mfa_reset',
  LAST_PW_RESET = 'last_pw_reset',
}

export enum AwsResourceType {
  GROUPS = 'groups',
  INLINE_POLICIES = 'inline_policies',
  MANAGED_POLICIES = 'managed_policies',
  ROLES = 'roles',
}

export type NodeImportStateT = {
  id: string;
  rid: string;
  importStatesId: string;
  refId: string;
  refType: string;
  warning: boolean;
  createdAt: string;
  data: any;
  usage: number;
  updatedAt: string;
};

export enum ReferenceType {
  System = 'system',
  Resource = 'resource',
  Permission = 'permission',
  UserAccount = 'user_account',
  Access = 'access',
  Teams = 'teams',
}

export enum LegalDocName {
  TERMS_OF_SERVICE = 'Terms of Service',
  PRIVACY_POLICY = 'Privacy Policy',
  EULA = 'End-user License Agreement',
}

export type ProgressStatusInfo = {
  tasks: string[];
  current?: string;
  error: boolean;
  message?: string;
  finished: boolean;
  failedCount?: number;
  inProgressCount?: number;
};

export type ProgressStatusPerImport = {
  service: string;
  progressStatus: ProgressStatusInfo;
};

export enum ResourceCategoryEnum {
  RESOURCE = 'resource',
  SYSTEM = 'system',
  SERVICE = 'service',
  GROUP = 'group',
  ROLE = 'role',
  APPLICATION = 'application',
  MANAGED_POLICY = 'managed_policy',
  INLINE_POLICY = 'inline_policy',
  TEAM = 'team',
  PROJECT = 'project',
  REPOSITORY = 'repository',
  WORKBOOK = 'workbook',
  DATASOURCE = 'datasource',
  VIEW = 'view',
  SITES = 'sites',
}

export type IDPIntegration = {
  service: string;
  fieldMapping?: FieldMappingT;
  connectionId?: string;
};

export type FieldMappingT = _.Dictionary<{name: string; mandatory: boolean; regex?: string}>;
export type IdpIntegrationOtherSettingsT = _.Dictionary<any>;

// this will be the key for FieldMappingT dictionary
export enum FieldMappingAttrType {
  EMAIL = 'email',
  MANAGER = 'manager',
  USER_TYPE = 'type', // TODO (GO) this needs to be deleted when the regex support is ready, it will be replaced by the 4 user types defined below
  DEPARTMENT = 'department',
  ROLE = 'role',
  TITLE = 'title',
  // user types
  EMPLOYEE = 'employee',
  CONTRACTOR = 'contractor',
  CUSTOMER = 'customer',
  SYSTEM = 'system',
}

export enum IntegrationService {
  OKTA = 'okta',
  AZURE_AD = 'azure_ad',
  GAPPS = 'gapps',
}

const integrationMap: _.Dictionary<IntegrationService> = {
  [ConnectionServiceE.OKTA]: IntegrationService.OKTA,
  [ConnectionServiceE.AZURE_AD]: IntegrationService.AZURE_AD,
  [ConnectionServiceE.GAPPS]: IntegrationService.GAPPS,
};

export const getIntegrationService = (service?: ConnectionServiceE): IntegrationService | undefined => {
  return service && integrationMap[service];
};

const serviceMap: _.Dictionary<ConnectionServiceE> = {
  [IntegrationService.OKTA]: ConnectionServiceE.OKTA,
  [IntegrationService.AZURE_AD]: ConnectionServiceE.AZURE_AD,
  [IntegrationService.GAPPS]: ConnectionServiceE.GAPPS,
};

export const getConnectionService = (integration?: IntegrationService): ConnectionServiceE | undefined => {
  return integration && serviceMap[integration];
};

export type RiskScorePermissionsT = {
  accessId: string;
  description: string;
  foundRisks: any[];
  globalOddity: number;
  holdersAccount: string;
  name: string;
  oddityByCluster: number;
  oddityByTeam: number;
  pid: string;
  resourceName: string;
  rid: string;
  riskScore: number;
  useDates: any[];
};

export enum MessageTypeE {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
  DANGER = 'danger',
}
